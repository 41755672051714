import { createContext, useContext, useEffect, useMemo, useState } from "react";
import dcCertificateAPI from "../api/DcCertificate/config"
import {getSessionRefreshToken, getSessionRole, getSessionToken, removeSession, setSession} from "../utils/sessionData";

// @ts-ignore
const AuthContext = createContext();

// @ts-ignore
const AuthProvider = ({ children }) => {
  const [token, setToken_] = useState(getSessionToken());
  const [role, setRole_] = useState(getSessionRole());
  const [refreshToken, setRefreshToken_] = useState(getSessionRefreshToken());

  // @ts-ignore
  const setToken = (newToken) => {
    setToken_(newToken);
  };

  // @ts-ignore
  const setRole = (newRole) => {
    setRole_(newRole);
  };

  // @ts-ignore
  const setRefreshToken = (newRefreshToken) => {
    setRefreshToken_(newRefreshToken);
  };

  useEffect(() => {
    if (token && role && refreshToken) {
      dcCertificateAPI.defaults.headers.common["Authorization"] = "Bearer " + token;

      setSession({
        token: token,
        refreshToken: refreshToken,
        role: role,
        cache: JSON.stringify([])
      })
    } else {
      delete dcCertificateAPI.defaults.headers.common["Authorization"];
      removeSession();
    }
  }, [token, role, refreshToken]);

  const contextValue = useMemo(
    () => ({
      token,
      setToken,
      role,
      setRole,
      refreshToken,
      setRefreshToken
    }),
    [token, role, refreshToken]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
