import styled from "@emotion/styled";

const Button = styled.button`
    padding: 16px 32px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    color: #ffffff;
    transition: 0.3s;
    cursor: pointer;

    &.btn-primary {
        background-color: #2A5595;

        &:hover {
            background-color: rgba(42, 85, 149, 0.6);
        }
    }
    
    &.btn-secondary {
        background-color: #e40781;

        &:hover {
            background-color: rgba(228, 7, 129, 0.6);
        }
    }
    
    &.btn-disabled {
        background-color: #C1C1C1;

        &:hover {
            background-color: rgba(193, 193, 193, 0.6);
        }
    }

    &.btn-vidaas {
        background-color: #00AEE9;

        &:hover {
            background-color: rgba(0, 174, 223, 0.6);
        }
    }
`

export default Button