import styled from "@emotion/styled";

const Text = styled.p`
  padding: 8px;
  transition: 0.3s;
    
  &.error {
      color: #ED3257;
      border-radius: 8px;
      padding: 16px 8px;
  }
    
  &.warning {
      color: #896525;
      border-radius: 8px;
      padding: 16px 8px;
  }
  
  &.bg-warning {
      text-align: left;
      color: #896525;
      background-color: #F9D492;
      display: flex;
      flex-direction: column;
      padding: 16px 16px;
      row-gap: 8px;

      svg {
          color: #896525;
          font-weight: bold;
          font-size: 22px;
          margin: 0 8px 0 0;
      }

      span {
          display: flex;
          align-items: center;
      }
  }
    
  &.bg-error {
      text-align: left;
      color: #ED3257;
      background-color: #FBD0D9;
      display: flex;
      flex-direction: column;
      padding: 16px 16px;
      row-gap: 8px;

      svg {
          color: #ED3257;
          font-weight: bold;
          font-size: 22px;
          margin: 0 8px 0 0;
      }
      
      span {
          display: flex;
          align-items: center;
      }
  }
`

export default Text