import styled from "@emotion/styled";

const Content = styled('div')`
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding: 40px 16px;

    @media (max-width:480px)  {
        padding: 40px 16px;
    }
`

// @ts-ignore
export default Content