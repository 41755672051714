import PageContent from "../components/PageContent";
import i18n from "../i18n";
import {useParams} from "react-router-dom";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import Link from "../components/Link";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from "@emotion/styled";
import {formatDate} from "../utils/date";
import RoleAction from "../components/RoleAction";
import Role from "../enums/Role";
import CertificateStatus from "../enums/CertificateStatus";

const StyledTh = styled(Th)`
    padding: 8px;
    border: 1px solid #000000;
    background-color: #2A5595;
    color: #ffffff;
`

const StyledTd = styled(Td)`
    padding: 8px;
    border: 1px solid #000000;
`

// TODO: validate certificate status???
const RevokedCertificatePage = () => {
  let { protocol } = useParams();
  const queryCertificate : UseQueryResult<AxiosResponse<ICertificateResponse>> = useQuery({
    queryKey: ['certificate'],
    queryFn: async () => {
      if (protocol) return await DcCertificateAPI.v1.certificate(protocol)
    },
  })

  return (
    <PageContent>
      {
        queryCertificate.error && (<Text className={"error bg-error"}>
          <span><TfiInfoAlt/><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
        </Text>)
      }

      {
        queryCertificate.isLoading && (
          <BeatLoader color="#8d8d8d" loading size={24}/>
        )
      }

      {
        queryCertificate.isSuccess && (
          <>
            <header>
              <h2>{i18n.ptBr.Pages.CertificatePage.title}</h2>
            </header>

            <h2>{queryCertificate.data?.data?.protocol}</h2>

            <p>{queryCertificate.data?.data?.product}</p>

            <Table>
              <Thead className={"transparent"}>
                <Tr>
                  <StyledTh colSpan={"2"}>{i18n.ptBr.Pages.CertificatePage.detailsTitle}</StyledTh>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.cpf}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.cpf}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.client}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.name}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.birthDate}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.dateOfBirth ? formatDate(queryCertificate.data?.data?.dateOfBirth) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.email}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.email}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.status}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.status.description}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.creationDate}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.dateCreated ? formatDate(queryCertificate.data?.data?.dateCreated) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.initialValidity}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.initialExpirationDate ? formatDate(queryCertificate.data?.data?.initialExpirationDate) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.finalValidity}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.finalExpirationDate ? formatDate(queryCertificate.data?.data?.finalExpirationDate) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.revocationDate}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.dateRevoke ? formatDate(queryCertificate.data?.data?.dateRevoke) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.serviceUnit}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.serviceUnit}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.agrValidation}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.avp}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.agrInformationCheck}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.aci}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.emitterSystem}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.emitterSystem.description}</StyledTd>
                </Tr>
              </Tbody>
            </Table>

            <Table>
              <Thead className={"transparent"}>
                <Tr>
                  <StyledTh colSpan={"2"}>{i18n.ptBr.Pages.CertificatePage.certificateDetailsTitle}</StyledTh>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.serialNumber}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.serialNumber}</StyledTd>
                </Tr>
                <Tr>
                  <StyledTd><strong>{i18n.ptBr.Pages.CertificatePage.certificateDetails.idn}</strong></StyledTd>
                  <StyledTd>{queryCertificate.data?.data?.idn}</StyledTd>
                </Tr>
              </Tbody>
            </Table>

            <footer className={"full-width"}>
              <Link className={"btn-decoration-underline"}
                    to={"/certificates"}>{i18n.ptBr.Components.Buttons.back}</Link>

              <RoleAction
                className={"btn-secondary"}
                elementType={"link"}
                onClick={undefined}
                roleRequired={Role.AGENT}
                linkTo={`/certificates/${queryCertificate.data?.data?.protocol}/revoke`}
                buttonType={undefined}
                hasStatus={queryCertificate.data?.data?.status.id === CertificateStatus.ISSUED}
              >
                {i18n.ptBr.Components.Buttons.revoke}
              </RoleAction>
            </footer>
          </>
        )
      }
    </PageContent>
  )
}

export default RevokedCertificatePage