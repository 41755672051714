import {useEffect, useState} from "react";
import Link from "./Link";
import Button from "./Button";
import {getSessionRole} from "../utils/sessionData";

// @ts-ignore
const RoleAction = ({ elementType, roleRequired, hasStatus, className, linkTo, buttonType, onClick, children }) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    const userRole = getSessionRole();
    // @ts-ignore
    setRole(userRole);
  }, [])

  const hasPermission = (role === roleRequired) && hasStatus;

  if (elementType === 'button') {
    return (
      <Button className={`${className} ${!hasPermission ? 'disabled-button' : ''}`} disabled={!hasPermission} type={buttonType} onClick={onClick}>
        {children}
      </Button>
    )
  } else if (elementType === 'link') {
    return (
      <Link className={`${className} ${!hasPermission ? 'disabled-link' : ''}`} to={hasPermission ? linkTo : ""}>{children}</Link>
    )
  } else {
    return null;
  }
}

export default RoleAction;