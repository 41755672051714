import PageContent from "../components/PageContent";
import Form from "../components/Form";
import Link from "../components/Link";
import i18n from "../i18n";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";

import SelectField from "../components/SelectField";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import ModalToRevoke from "../components/ModalToRevoke";
import {useMutation, useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import {BeatLoader} from "react-spinners";
import IReasonType from "../interfaces/IReasonType";
import ISubReasonType from "../interfaces/ISubReasonType";
import { formatDate } from "../utils/date"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from "@emotion/styled";
import RoleAction from "../components/RoleAction";
import Role from "../enums/Role";
import CertificateStatus from "../enums/CertificateStatus";

const StyledTh = styled(Th)`
    padding: 8px;
    border: 1px solid #000000;
    background-color: #2A5595;
    color: #ffffff;
`

const StyledTd = styled(Td)`
    padding: 8px;
    border: 1px solid #000000;
`

const RevokeCertificatePage = () => {
  let { protocol } = useParams();
  const { getValues, register, handleSubmit , formState: { errors: formErrors }} = useForm<any>()
  const [toggleModal, setToggleModal] = useState(false)

  const queryCertificate : UseQueryResult<AxiosResponse<ICertificateResponse>> = useQuery({
    queryKey: ['certificate'],
    queryFn: async () => {
      if (protocol) return await DcCertificateAPI.v1.certificate(protocol)
    }
  })

  const queryReasonList : UseQueryResult<AxiosResponse<Array<IReasonType>>> = useQuery({
    queryKey: ['reasonList'],
    queryFn: async () => {
      // @ts-ignore
      return await DcCertificateAPI.v1.reasonList(queryCertificate.data?.data?.certificateType?.id.toString())
    },
    enabled: queryCertificate.isSuccess
  })

  const muationSubReasonList = useMutation({
    mutationKey: ['subReasonList'],
    mutationFn: async (reasonType: any) => {
      return await DcCertificateAPI.v1.subReasonList(reasonType)
    },
    onMutate: () => {},
    onSuccess: (response: any) => {
      console.log("muationSubReasonList onSuccess: ", response)
    },
    onError: (error: any) => {
      console.error("muationSubReasonList onError: ", error)
    }
  })

  const onSubmit: SubmitHandler<any> = (data: any) => {
    setToggleModal(true)
  }

  const cancelRevoke = () => setToggleModal(false)

  const onReasonChange = (event:any) => {
    muationSubReasonList.mutate(event.target.value ? event.target.value: "noReasonType")
  }

  return (
    <>
      <ModalToRevoke
        open={toggleModal}
        onCancel={cancelRevoke}
        formData={() => getValues()}
        statusId={queryCertificate.data?.data?.status.id}
      />

      <PageContent>
        <header>
          <h2>{i18n.ptBr.Pages.RevokeCertificatePage.title}</h2>
        </header>

        {
          queryCertificate.error && (<Text className={"error bg-error"}>
            <span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
          </Text>)
        }

        {
          queryCertificate.isLoading && (
            <BeatLoader color="#8d8d8d" loading size={24}  />
          )
        }

        {
          queryCertificate.isSuccess && (
            <>
              <div style={{overflowX: "auto", width: "100%"}}>
                <Table>
                  <Thead className={"transparent"}>
                  <Tr>
                    <StyledTh>{i18n.ptBr.Pages.RevokeCertificatePage.table.thead.columnProtocol}</StyledTh>
                    <StyledTh>{i18n.ptBr.Pages.RevokeCertificatePage.table.thead.columnProduct}</StyledTh>
                    <StyledTh>{i18n.ptBr.Pages.RevokeCertificatePage.table.thead.columnStatus}</StyledTh>
                    <StyledTh>{i18n.ptBr.Pages.RevokeCertificatePage.table.thead.columnExpireDate}</StyledTh>
                  </Tr>
                  </Thead>
                  <Tbody>
                  <Tr>
                    <StyledTd><Link className={"btn-decoration-underline text text-secondary"} style={{display: "inline"}} to={`/certificates/${queryCertificate.data?.data?.protocol}`}><strong>{queryCertificate.data?.data?.protocol}</strong></Link></StyledTd>
                    <StyledTd>{queryCertificate.data?.data?.product}</StyledTd>
                    <StyledTd>{queryCertificate.data?.data?.status.description}</StyledTd>
                    <StyledTd>{queryCertificate.data?.data?.finalExpirationDate ? formatDate(queryCertificate.data?.data?.finalExpirationDate) : i18n.ptBr.ErrorMessage.undefinedData}</StyledTd>
                  </Tr>
                  </Tbody>
                </Table>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                  <div>
                    <label>{i18n.ptBr.Pages.RevokeCertificatePage.form.fields.reason.label}</label>
                    {
                      queryReasonList.error && (<Text className={"error bg-error"}>
                        <span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
                      </Text>)
                    }

                    {
                      queryReasonList.isLoading && (
                        <BeatLoader color="#8d8d8d" loading size={24}  />
                      )
                    }

                    {
                      queryReasonList.isSuccess && (
                        <SelectField
                          error={Boolean(formErrors.reason)}
                          {...register("reason", {required: true})}
                          onChange={onReasonChange}
                        >
                          <option value="">Selecione um motivo</option>
                          {
                            queryReasonList.data?.data.map((reason: IReasonType) => <option key={reason.id} value={reason.id}>{reason.description}</option>)
                          }
                        </SelectField>
                      )
                    }
                  </div>

                  <div>
                    <label>{i18n.ptBr.Pages.RevokeCertificatePage.form.fields.subReason.label}</label>

                    {
                      muationSubReasonList.isIdle && (
                        <Text className={"warning bg-warning"}>
                          <span><TfiInfoAlt /><span>{i18n.ptBr.Pages.RevokeCertificatePage.form.fields.subReason.warningText}</span></span>
                        </Text>
                      )
                    }

                    {
                      muationSubReasonList.error && (<Text className={"error bg-error"}>
                        <span><TfiInfoAlt /><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
                      </Text>)
                    }

                    {
                      muationSubReasonList.isPending && (
                        <BeatLoader color="#8d8d8d" loading size={24}  />
                      )
                    }

                    {
                      muationSubReasonList.isSuccess && (
                        <SelectField
                          error={Boolean(formErrors.reason)}
                          {...register("subReason", {required: true})}
                        >
                          <option value="">Selecione um sub-motivo</option>
                          {
                            muationSubReasonList.data?.data.map((subReason: ISubReasonType) => <option key={subReason.id} value={subReason.id}>{subReason.description}</option>)
                          }
                        </SelectField>
                      )
                    }
                  </div>
                </fieldset>

                {(formErrors.reason || formErrors.subReason) && (
                  <Text className={"error bg-error"}>
                    {formErrors.reason && (
                      <span><TfiInfoAlt/><span>{i18n.ptBr.ErrorMessage.missingReasonError}</span></span>)}
                    {formErrors.subReason && (
                      <span><TfiInfoAlt/><span>{i18n.ptBr.ErrorMessage.missingSubReasonError}</span></span>)}
                  </Text>
                )}

                <footer>
                  <Link className={"btn-decoration-underline"}
                        to={"/certificates"}>{i18n.ptBr.Components.Buttons.back}</Link>

                  <RoleAction
                    className={"btn-secondary"}
                    elementType={"button"}
                    onClick={undefined}
                    roleRequired={Role.AGENT}
                    linkTo={undefined}
                    buttonType={"submit"}
                    hasStatus={queryCertificate.data?.data?.status.id === CertificateStatus.ISSUED}
                  >
                    {i18n.ptBr.Components.Buttons.revoke}
                  </RoleAction>
                </footer>
              </Form>
            </>
          )
        }
      </PageContent>
    </>
  )
}

export default RevokeCertificatePage