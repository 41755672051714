import PageContent from "../components/PageContent";
import i18n from "../i18n";
import {useSearchParams} from "react-router-dom";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import IAuthenticateAgentSslRequest from "../interfaces/IAuthenticateAgentSslRequest";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import {useState} from "react";
import BoxContent from "../components/BoxContent";
import {removeSession, setSession} from "../utils/sessionData";

const AuthenticationSslPage = () => {
  const [searchParams] = useSearchParams();
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.userNotFoundOrDoesNotExist)

  const sessionId = searchParams.get("sessionId")
  const message = searchParams.get("message")

  const queryAuthenticateAgentSsl : UseQueryResult<AxiosResponse<ICertificateResponse>> = useQuery({
    queryKey: ['authenticateAgentSsl'],
    queryFn: async () => {
      // @ts-ignore
      const authenticateAgentSslRequest: IAuthenticateAgentSslRequest = {sessionId}

      if (message) setMainErrorMessage(message)

      removeSession();

      const response = await DcCertificateAPI.v1.authenticateAgentSsl(authenticateAgentSslRequest)

      console.log("response", response)

      if (response.status >= 400 && message == null) {
        setMainErrorMessage(i18n.ptBr.ErrorMessage.userNotFoundOrDoesNotExist)
      } else if (response.status >= 500 && message == null) {
        setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
      } else {
        console.log("queryAuthenticateAgentSsl onSuccess: ", response)

        setSession({
          token: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          role: response.data.role,
          cache: JSON.stringify([])
        })

        window.location.replace("/certificates")
      }

      return response
    },
    retry: false
  })

  return (
    <PageContent>
      <BoxContent>
        <img
          src="/logo-vcert.png"
          alt="logo"
          loading="lazy"
          width={240}
        />

        {
          queryAuthenticateAgentSsl.error && (<Text className={"error bg-error"}>
            <span><TfiInfoAlt/><span>{mainErrorMessage}</span></span>
          </Text>)
        }

        {
          (queryAuthenticateAgentSsl.isLoading || queryAuthenticateAgentSsl.isSuccess) && (
            <>
              <p><strong>{i18n.ptBr.Pages.AuthenticationPage.definition}</strong></p>
              <p>{i18n.ptBr.Pages.AuthenticationPage.explanation}</p>
              <p><BeatLoader color="#000000" size={20}/></p>
            </>
          )
        }
      </BoxContent>
    </PageContent>
  )
}

export default AuthenticationSslPage