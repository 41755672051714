import styled from '@emotion/styled'

const BoxContent = styled.section`
    padding: 80px 32px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 32px;
    max-width: 420px;
    text-align: center;
    
    p {
        line-height: 26px;
    }
    
    button, a {
        width: 100%;
    }
`

export default BoxContent