import axios from "axios";
import refreshToken from "./refreshToken";
import {getSessionToken} from "../../utils/sessionData";

const onFulfilled = (response: any) => {
  return response;
}

const onRejected = async (error: any) => {
  if (error.response.status === 401) {
    delete dcCertificateAPI.defaults.headers.common["Authorization"];

    const refreshed = await refreshToken()

    if (refreshed) {
      const authorizationContent = "Bearer " + getSessionToken();

      dcCertificateAPI.defaults.headers.common["Authorization"] = authorizationContent

      error.config.headers["Authorization"] = authorizationContent

      return dcCertificateAPI(error.config)
    }
  }

  return Promise.reject(error);
}

const dcCertificateAPI = axios.create({
  baseURL: process.env.REACT_APP_DC_CERTIFICATE_HOST,
});

if (getSessionToken()) dcCertificateAPI.defaults.headers.common["Authorization"] = "Bearer " + getSessionToken();

dcCertificateAPI.defaults.headers.common["x-apikey"] = process.env.REACT_APP_APIKEY;

dcCertificateAPI.interceptors.response.use(onFulfilled, onRejected);

export default dcCertificateAPI