import styled from "@emotion/styled";

const ModalContent = styled.div`
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2%;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);

    display: ${(props: any) => (props.open ? 'flex !important' : 'none !important')};

    section {
        border-radius: 8px;
        background-color: #ffffff;
        padding: 40px 32px;
        text-align: center;
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        h3, p {
            color: #696969;
        }

        svg {
            margin: 0 auto;
            font-size: 72px;
            color: #F9D492;
        }

        footer {
            display: flex;
            justify-content: space-around;
        }
    }
`

export default ModalContent