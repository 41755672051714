import PageContent from "../components/PageContent";
import i18n from "../i18n";
import {useSearchParams} from "react-router-dom";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import IAuthenticateAgentRequest from "../interfaces/IAuthenticateAgentRequest";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import {useState} from "react";
import BoxContent from "../components/BoxContent";
import {removeSession, setSession} from "../utils/sessionData";

const AuthenticationVidaasPage = () => {
  const [searchParams] = useSearchParams();
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.userNotFoundOrDoesNotExist)

  const code = searchParams.get("code")
  const opaque = searchParams.get("opaque")

  const queryAuthenticateAgent : UseQueryResult<AxiosResponse<ICertificateResponse>> = useQuery({
    queryKey: ['authenticateAgent'],
    queryFn: async () => {
      // @ts-ignore
      const authenticateAgentRequest: IAuthenticateAgentRequest = {code, opaque}

      removeSession();

      const response = await DcCertificateAPI.v1.authenticateAgent(authenticateAgentRequest)

      console.log("response", response)

      if (response.status >= 400) {
        setMainErrorMessage(i18n.ptBr.ErrorMessage.userNotFoundOrDoesNotExist)
      } else if (response.status >= 500) {
        setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
      } else {
        console.log("queryAuthenticateAgent onSuccess: ", response)

        setSession({
          token: response.data.accessToken,
          refreshToken: response.data.refreshToken,
          role: response.data.role,
          cache: JSON.stringify([])
        })

        window.location.replace("/certificates")
      }

      return response
    },
    retry: false
  })

  return (
    <PageContent>
      <BoxContent>
        <img
          src="/logo-vidaas.png"
          alt="logo"
          loading="lazy"
          width={240}
        />

        {
          queryAuthenticateAgent.error && (<Text className={"error bg-error"}>
            <span><TfiInfoAlt/><span>{mainErrorMessage}</span></span>
          </Text>)
        }

        {
          (queryAuthenticateAgent.isLoading || queryAuthenticateAgent.isSuccess) && (
            <>
              <p><strong>{i18n.ptBr.Pages.AuthenticationPage.definition}</strong></p>
              <p>{i18n.ptBr.Pages.AuthenticationPage.explanation}</p>
              <p><BeatLoader color="#000000" size={20}/></p>
            </>
          )
        }
      </BoxContent>
    </PageContent>
  )
}

export default AuthenticationVidaasPage