import DcCertificateAPI from "./index";
import {getSessionRefreshToken, getSessionToken, removeSession, setSession} from "../../utils/sessionData";

const refreshToken = async () => {
  const response = await DcCertificateAPI.v1.refreshToken({
    // @ts-ignore
    accessToken: getSessionToken(),
    // @ts-ignore
    refreshToken: getSessionRefreshToken()
  })

  if (response.status === 200) {
    setSession({
      token: response.data.accessToken,
      refreshToken: response.data.refreshToken,
      role: response.data.role,
      cache: JSON.stringify([])
    })

    return true
  } else if (response.status === 401) {
    removeSession();

    return false
  }
}

export default refreshToken