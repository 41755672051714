import ISessionData from "../interfaces/ISessionData";

const sessionKeys = {
  token: "token",
  refreshToken: "refreshToken",
  role: "role",
  cache: "cache"
}

export const removeSession = () => {
  removeSessionToken();
  removeSessionRefreshToken();
  removeSessionRole();
  removeSessionCache();
}

export const setSession = ({ token, refreshToken, role, cache }: ISessionData) => {
  setSessionToken(token);
  setSessionRefreshToken(refreshToken);
  setSessionRole(role);
  setSessionCache(cache);
}

export const setSessionToken = (token: string) => localStorage.setItem(sessionKeys.token, token)
export const getSessionToken = () => localStorage.getItem(sessionKeys.token)
export const removeSessionToken = () => localStorage.removeItem(sessionKeys.token)

export const setSessionRefreshToken = (refreshToken: string) => localStorage.setItem(sessionKeys.refreshToken, refreshToken)
export const getSessionRefreshToken = () => localStorage.getItem(sessionKeys.refreshToken)
export const removeSessionRefreshToken = () => localStorage.removeItem(sessionKeys.refreshToken)

export const setSessionRole = (role: string) => localStorage.setItem(sessionKeys.role, role)
export const getSessionRole = () => localStorage.getItem(sessionKeys.role)
export const removeSessionRole = () => localStorage.removeItem(sessionKeys.role)

export const setSessionCache = (cache: string) => localStorage.setItem(sessionKeys.cache, cache)
export const getSessionCache = () => localStorage.getItem(sessionKeys.cache)
export const removeSessionCache = () => localStorage.removeItem(sessionKeys.cache)