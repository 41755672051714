import PageContent from "../components/PageContent";
import i18n from "../i18n";
import {useParams} from "react-router-dom";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {AxiosResponse} from "axios";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import Text from "../components/Text";
import {TfiInfoAlt} from "react-icons/tfi";
import {BeatLoader} from "react-spinners";
import Link from "../components/Link";
import CertificateStatus from "../enums/CertificateStatus";

// TODO: validate certificate status???
const RevokedCertificatePage = () => {
  let { protocol } = useParams();
  const queryCertificate : UseQueryResult<AxiosResponse<ICertificateResponse>> = useQuery({
    queryKey: ['certificate'],
    queryFn: async () => {
      if (protocol) return await DcCertificateAPI.v1.certificate(protocol)
    },
  })

  return (
    <PageContent>
      {
        queryCertificate.error && (<Text className={"error bg-error"}>
          <span><TfiInfoAlt/><span>{i18n.ptBr.ErrorMessage.serverError}</span></span>
        </Text>)
      }

      {
        queryCertificate.isLoading && (
          <BeatLoader color="#8d8d8d" loading size={24}/>
        )
      }

      {
        (queryCertificate.isSuccess && queryCertificate.data?.data?.status.id === CertificateStatus.REVOKED) && (
          <>
            <header>
              <h3 className={"color-secondary"}>{i18n.ptBr.Pages.RevokedCertificatePage.revokedTitle}</h3>
            </header>

            <h2>{queryCertificate.data?.data?.protocol}</h2>

            <p>{queryCertificate.data?.data?.product}</p>

            <p className={"text-center"}>
              <strong>{i18n.ptBr.Pages.RevokedCertificatePage.revokedDescription}</strong></p>

            <Link className={"btn-secondary"}
                  to={"/certificates"}>{i18n.ptBr.Components.Buttons.backToCertificatesList}</Link>
          </>
        )
      }

      {
        (queryCertificate.isSuccess && queryCertificate.data?.data?.status.id !== CertificateStatus.REVOKED) && (
          <>
            <header>
              <h3 className={"color-secondary"}>{i18n.ptBr.Pages.RevokedCertificatePage.revokeTitle}</h3>
            </header>

            <h2>{queryCertificate.data?.data?.protocol}</h2>

            <p>{queryCertificate.data?.data?.product}</p>

            <p className={"text-center"}>
              <strong>{i18n.ptBr.Pages.RevokedCertificatePage.revokeDescription}</strong></p>

            <Link className={"btn-secondary"}
                  to={"/certificates"}>{i18n.ptBr.Components.Buttons.backToCertificatesList}</Link>
          </>
        )
      }
    </PageContent>
  )
}

export default RevokedCertificatePage