import {AxiosResponse} from "axios";
import dcCertificateAPI from "./config";
import IAuthenticateAgentRequest from "../../interfaces/IAuthenticateAgentRequest";
import IAuthenticateAgentResponse from "../../interfaces/IAuthenticateAgentResponse";
import ICertificateResponse from "../../interfaces/ICertificateResponse";
import IReasonType from "../../interfaces/IReasonType";
import ISubReasonType from "../../interfaces/ISubReasonType";
import IRevokeCertificateRequest from "../../interfaces/IRevokeCertificateRequest";
import IAuthenticateUrlResponse from "../../interfaces/IAuthenticateUrlResponse";
import IRefreshTokenRequest from "../../interfaces/IRefreshTokenRequest";
import IAuthenticateAgentSslRequest from "../../interfaces/IAuthenticateAgentSslRequest";

const authenticateUrl = async (): Promise<AxiosResponse<IAuthenticateUrlResponse>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/authenticate/url`)

const authenticateAgent = async (requestData: IAuthenticateAgentRequest): Promise<AxiosResponse<IAuthenticateAgentResponse>> =>
  dcCertificateAPI.post(`/v1/serasa/certificate/authenticate/agent`, requestData)

const authenticateAgentSsl = async (requestData: IAuthenticateAgentSslRequest): Promise<AxiosResponse<IAuthenticateAgentResponse>> =>
  dcCertificateAPI.post(`/v1/serasa/certificate/authenticate/agent/ssl`, requestData)

const list = async (searchCertificatesParams: string): Promise<AxiosResponse<Array<ICertificateResponse>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/list?${searchCertificatesParams}&size=50`)

const certificate = async (protocol: any): Promise<AxiosResponse<ICertificateResponse>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate?protocol=${protocol}`)

const reasonList = async (certificateType: any): Promise<AxiosResponse<Array<IReasonType>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/reason/list?certificateType=${certificateType}`)

const subReasonList = async (reasonType: any): Promise<AxiosResponse<Array<ISubReasonType>>> =>
  dcCertificateAPI.get(`/v1/serasa/certificate/sub-reason/list?reasonType=${reasonType}`)

const revoke = async (requestData: IRevokeCertificateRequest) =>
  dcCertificateAPI.put(`/v1/serasa/certificate/revoke`, requestData)

const refreshToken = async (refreshTokenRequest: IRefreshTokenRequest): Promise<AxiosResponse<IAuthenticateAgentResponse>> =>
  dcCertificateAPI.post(`/v1/serasa/certificate/authenticate/agent/refresh-token`, refreshTokenRequest)

const Endpoints = {
  v1: {
    authenticateUrl,
    authenticateAgent,
    revoke,
    list,
    certificate,
    reasonList,
    subReasonList,
    refreshToken,
    authenticateAgentSsl,
  }
}

export default Endpoints