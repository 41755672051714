const ErrorMessage = {
  serverError: "Falha na comunicação com o servidor.",
  authenticatePersonError: "Protocolo ou CPF ou CNPJ ou senha inválida.",
  missingProtocolOrCpfOrCnpjError: "Você precisa informar o Protocolo ou CPF ou CNPJ.",
  missingPasswordError: "Você precisa informar a senha de revogação.",
  missingReasonError: "Você precisa informar o motivo da revogação.",
  missingSubReasonError: "Você precisa informar o sub-motivo da revogação.",
  certificateNotFound: "Nenhum certificado foi encontrado.",
  undefinedData: "Data indefinida",
  unauthorized: "Usuário não autorizado",
  userNotFoundOrDoesNotExist: "O usuário informado não foi encontrado, ou não existe"
}

const Buttons = {
  cancel: "Cancelar",
  revoke: "Revogar",
  continue: "Continuar",
  select: "Selecionar",
  back: "Voltar",
  loginVidaas: "Login com VIDaaS",
  backToCertificatesList: "Voltar para a listagem de Certificados",
  search: "Pesquisar",
  loginA3: "Login com A3"
}

const Modal = {
  title: "Tem a certeza?",
  description: "Se revogar o certificado não será mais válido."
}

const LoginPage = {
  title: "Login",
  definition: "Para acessar, é necessário autenticar-se utilizando o aplicativo VIDaaS ou um Certificado Digital do tipo A3 (token ou cartão).",
  or: "ou"
}

const AuthenticationPage = {
  title: "Acessando a Plataforma",
  definition: "Autenticando seu usuário para garantir o acesso seguro.",
  explanation: "Você será redirecionado para a plataforma em instantes."
}

const CertificateListPage = {
  title: "Consultar Certificados",
  warningText: "Informe o Protocolo ou CPF ou CNPJ para listar os certificados.",
  emptyResult: "Nenhum certificado encontrado.",
  form: {
    fields: {
      identifier: {
        label: "Informe o Protocolo ou CPF ou CNPJ"
      }
    }
  },
  table: {
    thead: {
      columnProtocol: "Protocolo",
      columnProduct: "Produto",
      columnCPF: "CPF",
      columnCNPJ: "CNPJ",
      columnEmail: "Email",
      columnStatus: "Status",
      columnExpireDate: "Validade",
      columnAction: "Ação"
    }
  }
}

const RevokeCertificatePage = {
  title: "Solicitação de revogação",
  table: {
    thead: {
      columnProtocol: "Protocolo",
      columnProduct: "Produto",
      columnStatus: "Status",
      columnExpireDate: "Validade"
    }
  },
  form: {
    fields: {
      reason: {
        label: "Motivo da revogação"
      },
      subReason: {
        label: "Sub-motivo da revogação",
        warningText: "Selecione um motivo para listar os sub-motivos."
      }
    }
  }
}

const RevokedCertificatePage = {
  revokedTitle: "Certificado Revogado com Sucesso",
  revokeTitle: "Certificado não Revogado",
  revokedDescription: "A confirmação da revogação será encaminhada ao e-mail cadastrado no Certificado Digital.",
  revokeDescription: "Esse Certificado Digital ainda não foi revogado, realize o fluxo de revogação."
}

const CertificatePage = {
  title: "Certificado Digital",
  description: "Detalhes do certificado digital.",
  detailsTitle: "Detalhes",
  certificateDetailsTitle: "Dados do Certificado",
  certificateDetails: {
    cpf: "CPF",
    client: "Cliente",
    birthDate: "Data de Nascimento",
    email: "E-​mail",
    status: "Status",
    creationDate: "Data da criação",
    initialValidity: "Validade inicial",
    finalValidity: "Validade final",
    revocationDate: "Data de revogação",
    serviceUnit: "Unidade de atendimento",
    agrValidation: "AGR - Validação",
    agrInformationCheck: "AGR - Conferência da informação",
    aki: "AKI",
    ski: "SKI",
    serialNumber: "Número de série",
    emitterSystem: "Sistema emissor",
    idn: "IDN",
  }
}

const ptBR = {
  Pages: {
    LoginPage,
    AuthenticationPage,
    CertificateListPage,
    CertificatePage,
    RevokeCertificatePage,
    RevokedCertificatePage
  },
  Components: {
    Buttons,
    Modal
  },
  ErrorMessage
}

export default ptBR
