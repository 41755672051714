import {Navigate} from "react-router-dom";
import PrivateLayout from "./PrivateLayout";
import {useAuth} from "../providers/AuthProvider";

export const PrivateRoute = () => {
    // @ts-ignore
    const { token } = useAuth();

    if (!token) {
        return <Navigate to="/" />;
    }

    return <PrivateLayout />;
};
