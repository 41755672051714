import PageContent from "../components/PageContent";
import Link from "../components/Link";
import i18n from "../i18n";
import {useMutation} from "@tanstack/react-query";
import ICertificateResponse from "../interfaces/ICertificateResponse";
import DcCertificateAPI from "../api/DcCertificate";
import {BeatLoader} from "react-spinners";
import {TfiInfoAlt} from "react-icons/tfi";
import Text from "../components/Text";
import { formatDate } from "../utils/date"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import styled from "@emotion/styled";
import Button from "../components/Button";
import TextField from "../components/TextField";
import Form from "../components/Form";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import RoleAction from "../components/RoleAction";
import Role from "../enums/Role";
import CertificateStatus from "../enums/CertificateStatus";
import {getSessionCache, setSessionCache} from "../utils/sessionData";

const StyledTh = styled(Th)`
    padding: 8px;
    border: 1px solid #000000;
    background-color: #2A5595;
    color: #ffffff;
`

const StyledTd = styled(Td)`
    padding: 8px;
    border: 1px solid #000000;

    @media (min-width:480px) {
        max-width: 80px;
        word-wrap: break-word;
    }
`

const CertificateListPage = () => {
  const [mainErrorMessage, setMainErrorMessage] = useState(i18n.ptBr.ErrorMessage.serverError)
  const { register, handleSubmit , formState: { errors: formErrors }} = useForm<any>()
  const [hasErros, setHasErrors] = useState(false)
  const [spinner, setSpinner] = useState(false)

  const [cache, setCache] = useState(JSON.parse(getSessionCache() || "[]"))

  const mutationSearchCertificates = useMutation({
    mutationKey: ['searchCertificates'],
    mutationFn: async (searchCertificatesParams: string) => {
      // @ts-ignore
      return await DcCertificateAPI.v1.list(searchCertificatesParams)
    },
    onMutate: () => {
      setSpinner(true)
    },
    onSuccess: (response: any) => {
      setHasErrors(false)
      setSpinner(false)

      setSessionCache(JSON.stringify(response.data))
      setCache(response.data)
    },
    onError: (error: any) => {
      setHasErrors(true)
      setSpinner(false)

      if (error.response.status === 401) setMainErrorMessage(i18n.ptBr.ErrorMessage.unauthorized)
      else if (error.response.status === 404) setMainErrorMessage(i18n.ptBr.ErrorMessage.certificateNotFound)
      else if (error.response.status >= 500) setMainErrorMessage(i18n.ptBr.ErrorMessage.serverError)
    }
  })

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    let searchCertificatesParams: string = ""

    const CPF_SIZE = 11;
    const CNPJ_SIZE = 14;
    const PROTOCOL_SIZE_MIN = 10;
    const PROTOCOL_SIZE_MAX = 15;

    switch (data.identifier.length) {
      case CNPJ_SIZE:
        searchCertificatesParams = `cnpj=${data.identifier}`
        break;
      case CPF_SIZE:
        searchCertificatesParams = `cpf=${data.identifier}`
        break;
      case PROTOCOL_SIZE_MIN:
      case PROTOCOL_SIZE_MAX:
        searchCertificatesParams = `protocol=${data.identifier}`
        break;
    }

    if (searchCertificatesParams === "") {
      setHasErrors(true)
      setSpinner(false)
      setMainErrorMessage(i18n.ptBr.ErrorMessage.missingProtocolOrCpfOrCnpjError)
      setSessionCache(JSON.stringify([]))
      setCache([])
    } else {
      setHasErrors(false)
      mutationSearchCertificates.mutate(searchCertificatesParams)
    }
  }

  return (
    <PageContent>
      <header>
        <h2>{i18n.ptBr.Pages.CertificateListPage.title}</h2>
      </header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <div>
            <label>{i18n.ptBr.Pages.CertificateListPage.form.fields.identifier.label}</label>
            <TextField
              type={"number"}
              error={Boolean(formErrors.identifier)}
              {...register("identifier", {required: true, minLength: 10, maxLength: 15})}
            />
          </div>
        </fieldset>

        {(formErrors.identifier || hasErros || mutationSearchCertificates.error) && (
          <Text className={"error bg-error"}>
            {(formErrors.identifier && !hasErros) && (<span><TfiInfoAlt/><span>{i18n.ptBr.ErrorMessage.missingProtocolOrCpfOrCnpjError}</span></span>)}
            {hasErros && (<span><TfiInfoAlt /><span>{mainErrorMessage}</span></span>)}
          </Text>
        )}

        <footer>
          <Button className={"btn btn-primary"} type={"submit"}>
            {
              spinner ? (
                <BeatLoader color="#ffffff" loading={spinner} size={10}/>
              ) : (i18n.ptBr.Components.Buttons.search)
            }
          </Button>
        </footer>
      </Form>

      {
        (mutationSearchCertificates.isIdle && cache.length === 0) && (
          <Text className={"warning bg-warning"}>
            <span><TfiInfoAlt /><span>{i18n.ptBr.Pages.CertificateListPage.warningText}</span></span>
          </Text>
        )
      }

      {
        mutationSearchCertificates.isPending && (
          <BeatLoader color="#8d8d8d" loading size={24}  />
        )
      }

      {
        (mutationSearchCertificates.isSuccess && cache.length === 0) && (
              <Text className={"warning bg-warning"}>
            <span><TfiInfoAlt /><span>{i18n.ptBr.Pages.CertificateListPage.emptyResult}</span></span>
          </Text>
        )
      }

      {
        (cache.length > 0) && (
          (
            <div style={{overflowX: "auto", width: "100%"}}>
              <Table>
                <Thead>
                <Tr>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnProtocol}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnProduct}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnCPF}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnCNPJ}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnEmail}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnStatus}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnExpireDate}</StyledTh>
                  <StyledTh>{i18n.ptBr.Pages.CertificateListPage.table.thead.columnAction}</StyledTh>
                </Tr>
                </Thead>
                <Tbody>
                {
                  cache.map((certificate:ICertificateResponse) => (
                    <Tr key={certificate?.protocol}>
                      <StyledTd><Link className={"btn-decoration-underline text text-secondary"} style={{display: "inline"}} to={`/certificates/${certificate?.protocol}`}><strong>{certificate?.protocol}</strong></Link></StyledTd>
                      <StyledTd>{certificate?.product}</StyledTd>
                      <StyledTd>{certificate?.cpf}</StyledTd>
                      <StyledTd>{certificate?.cnpj}</StyledTd>
                      <StyledTd>{certificate?.email}</StyledTd>
                      <StyledTd>{certificate?.status.description}</StyledTd>
                      <StyledTd>
                        {certificate?.finalExpirationDate ? formatDate(certificate?.finalExpirationDate) : i18n.ptBr.ErrorMessage.undefinedData}
                      </StyledTd>
                      <StyledTd>
                        <RoleAction
                          className={"btn-decoration-underline text text-secondary"}
                          elementType={"link"}
                          onClick={undefined}
                          roleRequired={Role.AGENT}
                          linkTo={`/certificates/${certificate?.protocol}/revoke`}
                          buttonType={undefined}
                          hasStatus={certificate?.status.id === CertificateStatus.ISSUED}
                        >
                          {i18n.ptBr.Components.Buttons.revoke}
                        </RoleAction>
                      </StyledTd>
                    </Tr>
                  ))
                }
                </Tbody>
              </Table>
            </div>
          )
        )
      }
    </PageContent>
  )
}

export default CertificateListPage