import {PrivateRoute} from "../components/PrivateRoute";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import LoginPage from "../pages/LoginPage";
import CertificateListPage from "../pages/CertificateListPage";
import RevokeCertificatePage from "../pages/RevokeCertificatePage";
import RevokedCertificatePage from "../pages/RevokedCertificatePage";
import Header from "../components/Header";
import Content from "../components/Content";
import CertificatePage from "../pages/CertificatePage";
import AuthenticationVidaasPage from "../pages/AuthenticationVidaasPage";
import AuthenticationSslPage from "../pages/AuthenticationSslPage";

const Routes = () => {
    const publicRoutes = [
        {
            path: "*",
            element: <LoginPage />,
        },
        {
            path: "/",
            element: <LoginPage />,
        },
        {
            path: "/authentication",
            element: <AuthenticationVidaasPage />,
        },
        {
            path: "/authentication-ssl",
            element: <AuthenticationSslPage />,
        }
    ];

    const privateRoutes = [
        {
            path: "/certificates",
            element: <PrivateRoute />,
            children: [
                {
                    path: "*",
                    element: <CertificateListPage />,
                },
                {
                    path: "/certificates",
                    element: <CertificateListPage />,
                },
                {
                    path: "/certificates/:protocol",
                    element: <CertificatePage />,
                },
                {
                    path: "/certificates/:protocol/revoke",
                    element: <RevokeCertificatePage />,
                },
                {
                    path: "/certificates/:protocol/revoked",
                    element: <RevokedCertificatePage />,
                }
            ],
        },
    ];

    const router = createBrowserRouter([
        ...publicRoutes,
        ...privateRoutes,
    ]);

    return (
      <>
          <Header />

          <Content>
              <RouterProvider router={router} />
          </Content>
      </>
    );
}

export default Routes;